<template>
  <div v-if="item" class="_height-100 _display-flex _flex-direction-column">
    <i-row>
      <i-column>
        <p class="_padding-left-1">
          <span>{{ item.primary.title }}</span>
          <span v-if="item.primary.year">
            / <span>{{ item.primary.year }}</span></span
          >
        </p>
      </i-column>
    </i-row>
    <i-row>
      <i-column>
        <i-container>
          <div class="_embed _embed-16by9 _position-relative">
            <iframe
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="1920"
              height="1080"
              type="text/html"
              :src="
                urlify({ id: item.primary.video_id, type: item.primary.player })
              "
            ></iframe>
          </div>
        </i-container>
      </i-column>
    </i-row>
    <i-row class="_margin-top-1 _margin-bottom-1">
      <i-column>
        <i-button
          v-if="prev"
          link
          size="sm"
          variant="dark"
          @click="
            $router.push({
              name: 'Video',
              params: {
                id: slugify(prev.primary.title),
              },
            })
          "
          >PREV</i-button
        >
      </i-column>
      <i-column>
        <i-button
          link
          size="sm"
          variant="dark"
          @click="
            $router.push({
              name: 'Videos',
            })
          "
          >BACK</i-button
        >
      </i-column>
      <i-column>
        <i-button
          v-if="next"
          link
          size="sm"
          variant="dark"
          @click="
            $router.push({
              name: 'Video',
              params: {
                id: slugify(next.primary.title),
              },
            })
          "
          >NEXT</i-button
        >
      </i-column>
    </i-row>
  </div>
</template>

<script>
export default {
  name: "Video",
  data() {
    return {
      response: null,
      body: [],
      item: null,
      slug: "",
      total: 0,
      index: 0,
      next: null,
      prev: null,
    };
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle("videos");
      this.response = response;
      this.total = response.data.body.length;
      response.data.body.forEach((item, i) => {
        this.slug = this.slugify(item.primary.title);
        let video = {
          id: this.slug,
          item: item,
        };
        this.body.push(video);
        if (this.slug === this.$route.params.id) {
          this.item = item;
          this.index = i;
          if (i == 0) {
            this.prev = this.response.data.body[this.total - 1];
            this.next = this.response.data.body[i + 1];
          } else if (i == this.total - 1) {
            this.prev = this.response.data.body[i - 1];
            this.next = this.response.data.body[0];
          } else {
            this.prev = this.response.data.body[i - 1];
            this.next = this.response.data.body[i + 1];
          }
        }
      });
    },
    slugify(string) {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");
      return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
    urlify(video) {
      let url = "";
      if (video.type === "YouTube") {
        url = `https://www.youtube.com/embed/${video.id}?loop=1&autoplay=0&mute=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&modestbranding`;
      } else {
        url = `https://player.vimeo.com/video/${video.id}?autoplay=0&&muted=0&loop=1&color=ffffff&byline=0`;
      }
      return url;
    },
  },
  created() {
    this.getContent();
  },
};
</script>
