var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',{staticClass:"_height-100 _display-flex _flex-direction-column"},[_c('i-row',[_c('i-column',[_c('p',{staticClass:"_padding-left-1"},[_c('span',[_vm._v(_vm._s(_vm.item.primary.title))]),(_vm.item.primary.year)?_c('span',[_vm._v(" / "),_c('span',[_vm._v(_vm._s(_vm.item.primary.year))])]):_vm._e()])])],1),_c('i-row',[_c('i-column',[_c('i-container',[_c('div',{staticClass:"_embed _embed-16by9 _position-relative"},[_c('iframe',{attrs:{"frameborder":"0","scrolling":"no","marginheight":"0","marginwidth":"0","width":"1920","height":"1080","type":"text/html","src":_vm.urlify({ id: _vm.item.primary.video_id, type: _vm.item.primary.player })}})])])],1)],1),_c('i-row',{staticClass:"_margin-top-1 _margin-bottom-1"},[_c('i-column',[(_vm.prev)?_c('i-button',{attrs:{"link":"","size":"sm","variant":"dark"},on:{"click":function($event){_vm.$router.push({
            name: 'Video',
            params: {
              id: _vm.slugify(_vm.prev.primary.title),
            },
          })}}},[_vm._v("PREV")]):_vm._e()],1),_c('i-column',[_c('i-button',{attrs:{"link":"","size":"sm","variant":"dark"},on:{"click":function($event){return _vm.$router.push({
            name: 'Videos',
          })}}},[_vm._v("BACK")])],1),_c('i-column',[(_vm.next)?_c('i-button',{attrs:{"link":"","size":"sm","variant":"dark"},on:{"click":function($event){_vm.$router.push({
            name: 'Video',
            params: {
              id: _vm.slugify(_vm.next.primary.title),
            },
          })}}},[_vm._v("NEXT")]):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }